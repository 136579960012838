<template>
  <v-snackbar :value="notification" :color="color" :top="top"
    >{{ notificationText }}
  </v-snackbar>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({}),
  methods: {
    close() {
      // this.$store.commit()
    },
  },
  computed: {
    ...mapGetters({
      notificationText: 'snackbarFlashMessage/notificationText',
      notification: 'snackbarFlashMessage/notification',
      color: 'snackbarFlashMessage/color',
      top: 'snackbarFlashMessage/top',
    }),
  },
}
</script>
